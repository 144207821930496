<template>
    <div id="lista">
        <div class="tabela-lista">
            <div class="row">
                <!--filtros para a listagem das listas de transmissão-->
                <div class="col-12 filtros nova-lista">
                    <v-form ref="form_filtros">
                        <v-text-field
                            v-model="filtros.numero"
                            label="Número"
                            placeholder="Número"
                            v-mask="filtros.numero < 19 ? '+55 (##) ####-####' : '+55 (##) # ####-####'"
                            background-color="white"
                            hide-details
                            outlined
                        />
                        <v-text-field
                            v-model="filtros.identificacao"
                            label="Identificação"
                            placeholder="Identificação"
                            background-color="white"
                            hide-details
                            outlined
                        />
                        <v-select
                            v-model="filtros.estagio"
                            :items="estagios_filtro"
                            item-text="name"
                            item-value="uuid"
                            label="Estágio"
                            placeholder="Estágio"
                            background-color="white"
                            hide-details
                            outlined
                        /> 
                        <v-btn class="primary-button" style="text-align: center;" small @click="searchFiltro">
                            <i class="fas fa-filter" style="color: white; margin-left: 7px;" aria-hidden="true"></i>
                        </v-btn>
                        <v-btn class="primary-button" style="margin-left: 8px;" small @click="cleanFiltro">
                            <i class="fas fa-trash" style="color: white; margin-left: 7px;" aria-hidden="true"></i>
                        </v-btn>
                        <v-btn class="primary-button" style="margin-left: 8px;" small @click="dialog_lista = true">
                            <i class="fas fa-plus" style="color: white;" aria-hidden="true"></i>
                            Transmissão
                        </v-btn>
                    </v-form>
                </div>
            </div>
            <!--tabela que ira conter a listagem das listas de transmissão-->
            <CustomTable
                v-if="headers != ''"
                :action="'getTransmissoes'"
                :getter="$store.getters.listas"
                :headers="headers"
                :search="true"
                :title="'Transmissões'"
                :icone="'fa fa-rss'"
                :pagination="true"
                :filters="filtros"
                ref="tabela"
            >
                <template v-slot:[`numbers.number`]="{ item }">
					{{ formatarTelefone(item.numbers.number) }}
				</template>
                <template v-slot:[`numbers.scheduled_date`]="{ item }">
					{{ item.numbers.scheduled_date ? moment(item.numbers.scheduled_date).format('DD/MM/YYYY HH:mm:ss') : '' }}
				</template>
                <template v-slot:[`numbers.created_at`]="{ item }">
					{{ item.numbers.created_at ? moment(item.numbers.created_at).format('DD/MM/YYYY HH:mm:ss') : '' }}
				</template>
                <!--botões para ativação das modais presentes na tablela-->
                <template v-slot:opcoes="{ item }">
                    <v-tooltip bottom>
                        <template v-slot:activator="{ on, attrs }">
                            <v-btn class="primary-button" style="margin-left: 8px;" raised small @click="visualizarEstagio(item.numbers.uuid, item.name, item.identificador)"  v-bind="attrs" v-on="on">
                                <i class="fa fa-list" aria-hidden="true"></i>
                            </v-btn>
                        </template>  
                        <span>Visualizar Estagios</span>
                    </v-tooltip>
                </template>               
            </CustomTable>
            <!--modal para cadastro de novas listas de transmissão-->
            <v-dialog v-model="dialog_lista" persistente max-width="1200px">
                <v-card>
                    <v-card-title>
                        <span class="headline">Listas de Transmissão <v-icon>fa fa-rss</v-icon></span>
                    </v-card-title>
                    <v-card-text>
                        <v-container>
                            <v-form ref="form_lista">
                                <div class="row">
                                    <div class="col-3">
                                        <v-text-field
                                            v-model="lista.name"
                                            label="Nome da Lista"
                                            placeholder="Nome da Lista"
                                            background-color="white"
                                            hide-details
                                            outlined
                                        />
                                    </div>
                                    <div class="col-3">
                                        <v-select
                                            :rules="[v => !!v || 'Campo Empresa obrigatório']"
                                            :items="$store.getters.companies"
                                            item-text="name"
                                            item-value="uuid"
                                            v-model="lista.empresa"
                                            label="Empresa Responsável"
                                            placeholder="Empresa Responsável"
                                            background-color="white"
                                            hide-details
                                            outlined
                                            @change="botByCompany(lista.empresa)"
                                        />
                                    </div>
                                    <div v-if="!lista.planilha" class="col-3">
                                        <v-select
                                            :disabled="this.lista.empresa == ''"
                                            :rules="[v => !!v || 'Campo Bot obrigatório']"
                                            :items="bots"
                                            item-text="name"
                                            item-value="uuid"
                                            v-model="lista.bot"
                                            label="Bot Utilizado"
                                            placeholder="Bot Utilizado"
                                            background-color="white"
                                            hide-details
                                            outlined
                                        />
                                    </div>
                                    <div v-else class="col-3">
                                        <v-select
                                            :rules="[v => !!v || 'Campo Tipo obrigatório']"
                                            :items="tipos_planilha"
                                            item-text="name"
                                            item-value="id"
                                            v-model="planilha_id"
                                            label="Tipo de planilha"
                                            placeholder="Tipode de planilha"
                                            backgroud-color="white"
                                            hide-details
                                            outlined
                                        />
                                    </div>
                                    <div class="col-3">
                                        <v-text-field
                                            :rules="[v => !!v || 'Campo Fila obrigatório']"
                                            v-model="lista.queue"
                                            label="Fila responsável"
                                            placeholder="Fila responsável"
                                            background-color="white"
                                            hide-details
                                            outlined
                                        />
                                    </div>
                                </div>
                            </v-form>
                        <div class="col-12 importar-planilha">
                            <!--Define se será uma planilha personalizada-->
                            <v-switch
                                v-model="lista.planilha"
                                label="Planilha Personalizada"
                                color="orange"
                                hide-details
                                style="margin-left: 30%;"
                            />
                            <!--botão para importar planilhas do excel-->
                            <v-btn class="primary-button" raised style="margin-left: 5%; margin-top: 1%;" :disabled="lista.planilha && planilha_id == '' ? true : false" @click="dialog_importar_planilha = true">
                                <i class="fas fa-plus" aria-hidden="true"></i>
                                Importar Planilha
                            </v-btn>
                        </div>
                            <!--tabela que ira conter os abrangidos pela lista (comum)-->
                            <div v-if="!lista.planilha">
                                <v-data-table
                                    :headers="header_lista"
                                    :items="dados_lista"
                                >
                                    <template v-slot:top>
                                        <v-toolbar flat>
                                            <v-toolbar-title> 
                                                Números da Lista 
                                            </v-toolbar-title>
                                            <v-divider
                                                class="mx-4"
                                                inset
                                                vertical
                                            />
                                            <v-spacer></v-spacer>
                                            <!--modal para inserir um elemento na tabela de transmissão-->
                                            <v-dialog v-model="dialog_numero" persistent max-width="600px">
                                                <template v-slot:activator="{on, attrs}">
                                                    <v-btn class="primary-button" v-bind="attrs" v-on="on">
                                                        Novo Número
                                                    </v-btn>
                                                </template>
                                                <v-card>
                                                    <v-card-title>
                                                        <span class="headline">Dados Para Transmissão <v-icon>fa fa-address-card</v-icon></span>
                                                    </v-card-title>
                                                    <v-card-text>
                                                        <v-container>
                                                            <v-form ref="form_numero">
                                                                <div class="row">
                                                                    <div class="col-6">
                                                                        <v-text-field
                                                                            :rules="[v => !!v || 'Nome é obrigatório']"
                                                                            v-model="dados.name"
                                                                            label="Nome"
                                                                            placeholder="Nome"
                                                                            background-color="white"
                                                                            outlined
                                                                        />
                                                                    </div>
                                                                    <div class="col-6">
                                                                        <v-text-field
                                                                            v-model="dados.cpf"
                                                                            label="Identificação do Contato"
                                                                            placeholder="Identificação do Contato"
                                                                            background-color="white"
                                                                            outlined
                                                                        />
                                                                    </div>
                                                                    <div class="col-12">
                                                                        <v-textarea
                                                                            :rules="[v => !!v || 'Número é obrigatório']"
                                                                            v-model="dados.numbers"
                                                                            label="Números para Contato"
                                                                            placeholder="Números para Contato"
                                                                            background-color="white"
                                                                            readonly
                                                                            hide-details
                                                                            outlined
                                                                            @click="modalNumbers"
                                                                        />
                                                                    </div>
                                                                </div>
                                                            </v-form>
                                                        </v-container>
                                                    </v-card-text>
                                                    <v-card-actions>
                                                        <v-spacer></v-spacer>
                                                        <v-btn color="darken-1" text style="text-transform: capitalize; font-size: 16px;" @click="closeNumero">
                                                            Cancelar
                                                        </v-btn>
                                                        <v-btn class="primary-button" small @click="salvarNumero">
                                                            Transmitir
                                                        </v-btn>
                                                    </v-card-actions>
                                                </v-card>
                                            </v-dialog>
                                        </v-toolbar>
                                    </template>
                                    <!--botões para ativação das modais presentes na tabela-->
                                    <template v-slot:[`item.editar`]="{ item }">
                                        <v-btn class="primary-button" raised small @click="editarNumero(item)">
                                            <i class="fas fa-cog"></i>
                                        </v-btn>
                                    </template>
                                    <template v-slot:[`item.deletar`]="{ item }">
                                        <v-btn class="primary-button" raised small @click="removeNumero(item)">
                                            <i class="fa fa-trash"></i>
                                        </v-btn>
                                    </template>
                                </v-data-table>
                            </div>
                            <!--tabela que ira conter os abrangidos pela lista (tags)-->
                            <div v-if="lista.planilha && planilha_id == '1'">
                                <div v-if="tags_planilha.tags.length > 0" style="text-align: center;">
                                    <h3 style="margin-top: 2%;">
                                        Você possui as seguintes Tags para usar no seu texto: {{ this.texto_tags }} para referencia-las no seu texto as coloque entre { }. <br>
                                        Por exemplo: ... seu texto { {{ this.tags_planilha.tags[0] }} }
                                    </h3>
                                    <div class="col-12">
                                        <v-textarea
                                            :rules="[v => !!v || 'Mensagem disparada obrigatória']"
                                            v-model="mensagem_tags"
                                            label="Mensagem que será disparada"
                                            placeholder="Mensagem que será disparada"
                                            background-color="white"
                                            hide-details
                                            outlined
                                            style="margin-top: 2%;"
                                        />
                                    </div>
                                    <div class="col-12">
                                        <v-btn class="primary-button" raised small @click="formataTexto()">
                                            Confirmar Texto
                                        </v-btn>
                                    </div>
                                </div>
                                <v-data-table
                                    :headers="header_lista_tags"
                                    :items="dados_lista"
                                >
                                    <template v-slot:top>
                                        <v-toolbar flat>
                                            <v-toolbar-title>
                                                Números da lista
                                            </v-toolbar-title>
                                            <v-divider
                                                class="mx-4"
                                                inset
                                                vertical
                                            />
                                            <v-spacer></v-spacer>
                                        </v-toolbar>
                                    </template>
                                    <!--botões presentes na tabela-->
                                    <template v-slot:[`item.deletar`]="{ item }">
                                        <v-btn class="primary-button" raised small @click="removeNumero(item)">
                                            <i class="fa fa-trash"></i>
                                        </v-btn>
                                    </template>
                                </v-data-table>
                            </div>
                            <!--tabela que ira conter os abrangidos pela lista (mensagem personalizada)-->
                            <div v-if="lista.planilha && planilha_id == '2'">
                                <v-data-table
                                    :headers="header_lista_mensagem"
                                    :items="dados_lista"
                                >
                                    <template v-slot:top>
                                        <v-toolbar flat>
                                            <v-toolbar-title>
                                                Números da lista
                                            </v-toolbar-title>
                                            <v-divider
                                                class="mx-4"
                                                inset
                                                vertical
                                            />
                                            <v-spacer></v-spacer>
                                        </v-toolbar>
                                    </template>
                                    <!--botões presentes na tabela-->
                                    <template v-slot:[`item.deletar`]="{ item }">
                                        <v-btn class="primary-button" raised small @click="removeNumero(item)">
                                            <i class="fa fa-trash"></i>
                                        </v-btn>
                                    </template>
                                </v-data-table>
                            </div>
                        </v-container>
                    </v-card-text>
                    <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn color="darken-1" text style="text-transform: capitalize; font-size: 16px;" @click="closeLista">
                            Cancelar
                        </v-btn>
                        <v-btn class="primary-button" small @click="enviarLista" :disabled="this.dados_lista.length == 0 ? true : false">
                            Salvar
                        </v-btn>
                    </v-card-actions>
                </v-card>
            </v-dialog>
            <!--modal para cadastro de números para o fluxo-->
            <v-dialog v-model="dialog_adicionar_numeros" persistent max-width="550px">
                <v-card>
                    <v-card-title>
                        <span class="headline"><i class="fas fa-phone"></i> Números Para Contato</span>
                    </v-card-title>
                    <v-card-text>
                        <v-container>
                            <v-form ref="form_telefones">
                                <div class="row">
                                    <div class="col-12">
                                        <div class="menu" v-for="(numbers, i) in dados.numbers" :key="i">
                                            <div class="inputs">
                                                <div class="row">
                                                    <div class="col-12">
                                                        <v-text-field
                                                            :rules="[v => !!v || 'Campo Número Obrigatório', v => v.length > 19 || 'Campo Número Incompleto']"
                                                            v-model="dados.numbers[i]"
                                                            label="Número para Contato"
                                                            placeholder="Número para Contato"
                                                            v-mask="dados.numbers[i] < 19 ? '+55 (##) ####-####' : '+55 (##) # ####-####'"
                                                            hide-details
                                                            outlined
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                            <v-btn type="button" @click="removeContato(i)" color="primary" raised class="remover-menu">
                                                <i class="fas fa-minus"></i>
                                            </v-btn>
                                        </div>
                                    </div>
                                    <div class="col-12">
                                        <div class="row justify-end">
                                            <v-btn class="primary-button" style="margin-right: 12px" raised @click="adicionarContato">
                                                <i style="margin-right: 10px" class="fas fa-plus"></i> Adicionar Número
                                            </v-btn>
                                        </div>
                                    </div>
                                </div>
                            </v-form>
                        </v-container>
                    </v-card-text>
                    <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn color="darken-1" text style="text-transform: capitalize; font-size: 16px;" @click="closeAdicionarNumeros">
                            Cancelar
                        </v-btn>
                        <v-btn class="primary-button" small @click="adicionarNumeros()">
                            Salvar
                        </v-btn>
                    </v-card-actions>
                </v-card>
            </v-dialog>
            <!--modal para importação de planilhas do excel-->
            <v-dialog v-model="dialog_importar_planilha" persistent max-width="600px">
                <v-card>
                    <v-card-title>
                        <span class="headline">Importar Transmissão</span>
                    </v-card-title>
                    <v-card-text>
                        <v-container>
                            <v-form ref="form_planilha">
                                <div class="row">
                                    <div v-if="lista.planilha && planilha_id == '1'">
                                        <h4 style="text-align: center;">
                                            As colunas Telefone e Nome devem obrigatoriamente estar na planilha na primeira e segunda coluna respectivamente!<br>
                                            As demais colunas serão considerada como tags.
                                        </h4>
                                    </div>
                                    <v-btn class="primary-button" style="margin-left: 35%; margin-top: 3%;" small @click="redirecionarUsuario">
                                        Baixar Modelo
                                     </v-btn>
                                    <div class="col-12" style="margin-top: 3%;">
                                        <v-file-input
                                            v-model="planilha_excel"
                                            placeholder="Selecione o Arquivo"
                                            label="Selecione o Arquivo"
                                            accept=".xlsx"
                                            hide-details
                                            outlined
                                            prepend-icon="mdi-paperclip"
                                            @change="planilhaTransmissao"
                                        />
                                    </div>
                                </div>
                            </v-form>
                        </v-container>
                    </v-card-text>
                    <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn color="darken-1" text style="text-transform: capitalize; font-size: 16px;" @click="dialog_importar_planilha = false">
                            Cancelar
                        </v-btn>
                    </v-card-actions>
                </v-card>
            </v-dialog>
            <!--modal para os dados dos usuários da listagem-->
            <v-dialog v-model="dialog_dados_usuario_lista" persistent max-width="700px">
                <v-card>
                    <v-card-title>
                        <span class="headline">Dados do Contato</span>
                    </v-card-title>
                    <v-card-text>
                        <v-container>
                            <v-form ref="form_dados_usuario_lista">
                                <div class="col-12">
                                    <v-text-field
                                        v-model="dados_usuario.nome"
                                        label="Nome"
                                        placeholder="Nome"
                                        background-color="white"
                                        hide-details
                                        outlined
                                        readonly
                                    />
                                </div>
                                <div class="col-12">
                                    <v-text-field
                                        v-model="dados_usuario.identificacao"
                                        label="Identificação"
                                        placeholder="Identificação"
                                        background-color="white"
                                        hide-details
                                        outlined
                                        readonly
                                    />
                                </div>
                                <div class="col-12">
                                    <h3 class="text-center">Estágios Percorridos</h3>
                                    <v-simple-table>
                                        <template v-slot: default>
                                            <thead>
                                                <tr>
                                                    <th class="text-left">
                                                        Estágio
                                                    </th>
                                                    <th class="text-left">
                                                        Dia
                                                    </th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr v-for="item in estagios" :key="item.name">
                                                    <td>{{ item.nome }}</td>
                                                    <td>{{ item.dia }}</td>
                                                </tr>
                                            </tbody>
                                        </template>
                                    </v-simple-table>
                                </div>
                            </v-form>
                        </v-container>
                    </v-card-text>
                    <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn class="primary-button" small @click="closeEstagio">
                            OK
                        </v-btn>
                    </v-card-actions>
                </v-card>
            </v-dialog>
            <DialogMensagem :visible="dialog_resposta" :mensagem="resposta" @close="dialog_resposta=false"/>
            <Loader v-if="loading"/>
        </div>
    </div>
</template>
<!--funções para trazer e encaminhar os dados das listas-->
<script>
    // importa o store
    import store from '@/store'
    // importa o componente de DialogMensagem
    import DialogMensagem from '@/components/DialogMensagem.vue'
    // importa o componente de Loader
    import Loader from '@/components/Loader.vue'
    // importa o componente de CustomTable
    import CustomTable from '@/components/CustomTable.vue'
    // importa a biblioteca para utilizar o moment
    import moment from 'moment'
    // importa a biblioteca para ler os arquivos excel
	import readXlsxFile from 'read-excel-file'

    // exporta o componente
    export default {
        // nome do componente
        name: 'Transmissoes',
        // componentes filhos
        components: {
            // componente de DialogMensagem
            DialogMensagem,
            // componente de CustomTable
            CustomTable,
            // componente de Loader
            Loader,
        },
        // dados do componente
        data: () => ({
            // variável para mostrar a modal para criar uma lista de transmissão
            dialog_lista: false,
            // variável para mostrar a modal para cadastro e edição de um número para a lista
            dialog_numero: false,
            // variável para mostrar a modal de informação
            dialog_resposta: false,
            // variável para mostrar a modal de cadastro de números
            dialog_adicionar_numeros: false,
            // variável para mostrar a modal de importação de planilhas
            dialog_importar_planilha: false,
            // variável para mostrar a modal com os dados de um usuário da lista
            dialog_dados_usuario_lista: false,
            // variável para a mensagem de resposta
            resposta: {},
            // link para acessar a planilha modelo
            link_modelo: 'https://cdn.finer.com.br/sistemas/higienizadora/planilha_modelo.xlsx',
            // link para acessar a planilha modelo (tags)
            link_modelo_tags: 'https://cdn.finer.com.br/sistemas/higienizadora/planilha_tags.xlsx',
            // link para acessar a planilha modelo (mensagem personalizada)
            link_modelo_mensagem: 'https://cdn.finer.com.br/sistemas/higienizadora/planilha_personalizada.xlsx',
            // link para o iframe: 
            src: 'https://forum.vuejs.org/t/iframes-in-vue/102268',
            // variável para o loading
            loading: false,
            // variável para os dados da tabela da modal
            edited_index: -1,
            // variável para os bots
            bots: [],
            // variável para os estagios do filtro
            estagios_filtro: [],
            // variável para os dados dos números que farão parte da lista de transmissão
            dados: {
                name: '',
                numbers: [],
                cpf: '',
                message: '',
            },
            // variável para os limpar o vetor para a próxima posição
            dados_vazio: {
                name: '',
                numbers: [],
                cpf: '',
                message: '',
            },
            // variável para criar uma lista de transmissao
            lista: {
                name: '',
                empresa: '',
                bot: '',
                queue: '',
                planilha: false,
            },
            // variável para os dados de um usuário na listagem de transmissões
            dados_usuario: {
                uuid: '',
                numero: '',
                nome: '',
                identificacao: '',
            },
            // variável para mostrar os estágio percorridos pelo usuário
            estagios: [{
                nome: '',
                dia: '', 
            }],
            // variável para o item selecionado inicialmente na listagem
            item_selecionado: '',
            // variável para os dados de contato da lista
            dados_lista: [],
            // variável para armazenar a planilha do excel
            planilha_excel: [],
            // variável para armazenar os tipos de planilha existentes
            tipos_planilha: [
                {
                    id: 1,
                    name: 'Planilha de tags'
                },
                {
                    id: 2,
                    name: 'Mensagem Inicial personalizada'
                }
            ],
            // variável para armazenar o tipo de planilha que será utilizada
            planilha_id: '',
            // variável para armazenar as tags da planilha de tags
            tags_planilha: {
                tags: [],
                dados: []
            },
            // variável para armazenar o texto das tags
            texto_tags: '',
            // variável para armazenar a mensagem das tags
            mensagem_tags: '',
            // variável para o cabeçalho da tabela dos contatos da lista
            header_lista: [
                {
                    value: 'name',
                    text: 'Nome',
                    sortable: false,
                },
                {
                    value: 'numbers',
                    text: 'Número',
                    sortable: false,
                },
                {
                    value: 'cpf',
                    text: 'Identificação',
                    sortable: false,
                },
                {
                    value: 'editar',
                    text: 'Editar',
                    sortable: false,
                },
                {
                    value: 'deletar',
                    text: 'Deletar',
                    sortable: false,
                },
            ],
            // variável para o cabeçalho da tabela dos contatos da lista (tags)
            header_lista_tags: [
                {
                    value: 'name',
                    text: 'Nome',
                    sortable: false,
                },
                {
                    value: 'numbers',
                    text: 'Número',
                    sortable: false,
                },
                {
                    value: 'message',
                    text: 'Mensagem transmissão',
                    sortable: false,
                },
                {
                    value: 'deletar',
                    text: 'Deletar',
                    sortable: false,
                }
            ],
            // variável para o cabeçalho da tabela dos constatos da lista (mensagem personalizada)
            header_lista_mensagem: [
                {
                    value: 'numbers',
                    text: 'Número',
                    sortable: false,
                },
                {
                    value: 'message',
                    text: 'Mensagem Personalizada',
                    sortable: false
                },
                {
                    value: 'deletar',
                    text: 'Deletar',
                    sortable: false,
                },
            ],
            // variável para o cabeçalho da tabela com a listagem de transmissões
            headers: [
                {
                    value: 'list.name', 
                    text: 'Transmissão',
                    sortable: true,
                },
                {
                    value: 'name',
                    text: 'Pessoa',
                    sortable: true,
                },
                {
                    value: 'numbers.stages.name',
                    text: 'Estágio Atual',
                    sortable: true,
                },
                {
                    value: 'numbers.number',
                    text: 'Número',
                    sortable: true,
                },
                {
                    value: 'numbers.scheduled_date',
                    text: 'Data Agendamento',
                    sortable: true,
                },
                {
                    value: 'numbers.created_at',
                    text: 'Data de Cadastro',
                    sortable: true,
                },
                {
                    value: 'opcoes',
                    text: 'Opções',
                    sortable: false,
                },
            ],
            // variável para os filtros da tabela
            filtros: {
                perPage: 100,
                numero: '', 
                identificacao: '',
                estagio: '',
            },
        }),
        // funções deste componente
        methods: {
            // função de início deste componente
            async init(){
                // chama a função para puxar os estágios para o filtro
                await this.getStages()
            },
            // função para os filtros
            async searchFiltro(){
                // formata o número de telefone
                this.filtros.numero = this.filtros.numero.replace(/\D/g, '')
                // chama a função da tabela passando os dados dos campos
				this.$refs.tabela.init()
            },
            // função para limpar os filtros
            async cleanFiltro(){
                // retorna os filtros para sua configuração inicial
                this.filtros = {
                    perPage: 25,
                    numero: '', 
                    identificacao: '',
                    estagio: '',
                }
                // reinicia a tabela
				this.$refs.tabela.init()
            },
            // função para redirecionar o usuário para a planilha modelo
            async redirecionarUsuario(){
                // verifica se é uma planilha personalizada de tags
                if(this.lista.planilha && this.planilha_id == '1'){
                    // abre a janela com a planilha
                    window.open(this.link_modelo_tags, '_blank', 'location=yes,height=570,width=520,scrollbars=yes,status=yes')
                // verifica se é uma planilha com a mensagem inicial personalizada
                }else if(this.lista.planilha && this.planilha_id == '2'){
                    // abre a janela com a planilha
                    window.open(this.link_modelo_mensagem, '_blank', 'location=yes,height=570,width=520,scrollbars=yes,status=yes')
                // caso seja uma planiha simples
                }else{
                    // abre a janela com a planilha
                    window.open(this.link_modelo, '_blank', 'location=yes,height=570,width=520,scrollbars=yes,status=yes')
                }
            },
            // função para pegar os bots com base na company
            async botByCompany(company_uuid){
                // verificamos se vai ser necessário a busca
                if(!this.lista.planilha){
                    // coloca o componente como loading
                    this.loading = true
                    // faz um dispatch para uma action no vuex para pegar o bot passando a empresa
                    var resp = await store.dispatch('botByCompany', company_uuid)
                    // caso a requisição seja concluída com sucesso
                    if(resp.status == 200){
                        // atribui os dados vindo do back a variável local
                        this.bots = (resp.data.bots || [])
                    }else{
                        // atribui o título da mensagem
                        this.resposta.titulo = 'Algo deu errado!'
                        // atribui o corpo da mensagem
                        this.resposta.mensagem = await resp.data.message || resp.data.error
                        // mostra a mensagem
                        this.dialog_resposta = true
                    }
                    // retira o loading do componente
                    this.loading = false
                }
            },
            // função para pegar os estágios para o filtro
            async getStages(){
                // faz um dispatch para uma action no vuex para pegar os estágios
                var resp = await store.dispatch('getStages')
                // caso o status seja 200 (deu certo)
                if(resp.status == 200){
                    // atribui os dados a variável local
                    this.estagios_filtro = await resp.data.stage
                }
            },
            // função para distribuir os dados da planilha
            async planilhaTransmissao(){
                // caso os campos do formulário estejam corretos
                if(this.$refs.form_planilha.validate()){
                    // coloca o componente como loading
                    this.loading = true
                    // caso o usuário tenha selecionado uma planilha do excel
                    if(this.planilha_excel != ''){
                        // atribui os campos da planilha a uma variável local
                        let rows = await readXlsxFile(this.planilha_excel)
                        // caso a planilha possua dados
                        if(rows.length > 1){
                            // caso esteja sendo importada uma planilha comum
                            if(!this.lista.planilha){
                                // redirecionamos para a função
                                this.planilhaPadrao(rows)
                            // caso esteja sendo importada uma planilha de tags
                            }else if(this.planilha_id == '1'){
                                // redirecionamos para a função
                                this.planilhaTags(rows)
                            // caso esteja sendo importada uma planilha com a mensagem inicial personalizada
                            }else if(this.planilha_id == '2'){
                                // redirecionamos para a função
                                this.planilhaMensagem(rows)
                            }
                        }
                    }
                    // fecha a modal de importar planilha
                    this.dialog_importar_planilha = false
                    // limpa a variável 
                    this.planilha_excel = []
                    // limpa os dados locais
                    this.dados = {
                        name: '',
                        cpf: '',
                        numbers: '',
                        message: '',
                    }
                    // retira o loading do componente
                    this.loading = false
                }
            },
            // função para enviar uma lista de transmissão
            async enviarLista(){
                let resp = {}
                // caso os campos do formulário estejam válidos
                if(this.$refs.form_lista.validate()){
                    // caso seja uma planilha de tags
                    if(this.planilha_id != '1' || (this.planilha_id == '1' && this.dados_lista[0].message != '')){
                        // coloca o componente como loading
                        this.loading = true
                        // coleta os dados da lista de transmissão
                        let dados = {
                            // atribui o nome da lista
                            name: this.lista.name ? this.lista.name : 'Lista Dia ' + moment().format('l') + (' ') + moment().format('LT'),
                            // atribui a empresa responsável pela lista
                            company_uuid: this.lista.empresa,
                            // atribui o bot utilizado pela lista
                            bot_uuid: this.lista.bot,
                            // atribui a fila para transbordo da lista
                            queue_id: this.lista.queue,
                            // atribui os números da lista
                            list: this.dados_lista,
                            // atribui o tipo da planilha
                            spreadsheet: this.planilha_id == '' ? '0' : this.planilha_id.toString(),
                        }
                        // realiza a formatação dos dados da lista
                        for(let j = 0; j < dados.list.length; j++){
                            // verifica se existe cpf
                            if(dados.list[j].cpf){
                                // converte para string se necessário
                                if(typeof dados.list[j].cpf == 'number'){
                                    dados.list[j].cpf = dados.list[j].cpf.toString()
                                }
                                // formata o cpf do contato
                                dados.list[j].cpf = dados.list[j].cpf.replace(/\D/g, '')
                            }
                            // formata o número do contato
                            if(dados.list[j].numbers && typeof dados.list[j].numbers[0] != 'number'){
                                for(let i = 0; i < dados.list[j].numbers.length; i++){
                                    dados.list[j].numbers[i] = dados.list[j].numbers[i].replace(/\D/g, '')
                                }
                            }
                        }
                        // rota para a criação da lista
                        resp = await store.dispatch('postLista', dados)
                        // caso algo tenha dado errado
                        if(resp.status != 200 && resp.status != 201){
                            // atribui o título da mensagem
                            this.resposta.titulo = 'Algo deu errado!'
                            // atribui o corpo da mensagem
                            this.resposta.mensagem = await resp.data.message || resp.data.error
                            // mostra a mensagem
                            this.dialog_resposta = true
                        }else{
                            // atribui o título da mensagem
                            this.resposta.titulo = 'Lista cadastrada!'
                            // atribiu o corpo da mensagem
                            this.resposta.mensagem = 'Lista de Transmissão cadastrada com sucesso!'
                            // fecha a modal de criação de lista
                            this.closeLista()
                            // mostra a mensagem
                            this.dialog__resposta = true
                        }
                        // atualiza a tabela
                        this.$refs.tabela.init()
                        //retira o loading do componente
                        this.loading = false
                    // caso a mensagem não tenha sido repassada para a lista
                    }else{
                        // atribui o título da mensagem
                        this.resposta.titulo = 'Confirme o texto!'
                        // atribui o corpo da mensagem
                        this.resposta.mensagem = 'Confirme o texto que será disparado antes de transmitir!'
                        // mostra a mensagem
                        this.dialog_resposta = true
                    }
                }
            },
            // função para salvar um número e adicionar a tabela presente na modal
            async salvarNumero(){
                // verifica se está sendo editado um item
                if(this.edited_index > -1) {
                    // atualiza as informações do item
                    Object.assign(this.dados_lista[this.edited_index], this.dados)
                // caso seja um item novo
                }else{
                    // atribui os dado ao vetor
                    this.dados_lista.push(this.dados)
                }
                // fecha a modal de cadastro e edição de dados
                this.closeNumero()
            },
            // função para mostrar em que estágio da cobrança uma pessoa está  
            async visualizarEstagio(numero_usuario, nome_usuario, identificador_usuario){
                // coloca o componente como loading
                this.loading = true
                // faz um dispatch para uma action no vuex para pegar os estágios passando a pessoa
                var resp = await store.dispatch('getDados', numero_usuario)
                // caso o status da resposta seja 200 (deu certo)
                if(resp.status == 200){
                    // atribui o nome do usuário com base nos dados locais
                    this.dados_usuario.nome = nome_usuario
                    // atribui o cpf do usuário com base nos dados locais
                    this.dados_usuario.identificacao = identificador_usuario
                    // caso exista dados de estágios
                    if(resp.data.stage){
                        // faz as atribuições
                        for(let i = 0; i < resp.data.stage.length; i++){
                            if(this.estagios[i] == undefined){
                                this.estagios[i] = {
                                }
                            }
                            this.estagios[i].nome = resp.data.stage[i].stages.name
                            this.estagios[i].dia = moment(resp.data.stage[i].created_at).format("DD/MM/YYYY HH:mm")
                        }
                    // caso ainda não exista dados de estágios
                    }else{
                        this.estagios = [{
                            nome: '',
                            dia: '',
                            horario: '',
                        }]
                    }
                    // mostra a modal para vizualizar as informações
                    this.dialog_dados_usuario_lista = true
                // caso tenha dado algum erro no carregamento dos dados
                }else{
                    // atribui o título da mensagem
                    this.resposta.titulo = 'Algo deu errado!'
                    // atribui o corpo da mensagem
                    this.resposta.mensagem = resp.data.message || resp.data.error
                    // mostra a mensagem
                    this.dialog_resposta= true
                }
                // retira o loading do componente
                this.loading = false
            },
            // função para remover um número presente na modal
            async removeNumero(item){
                // atribui a posição dos dados que serão editados à variável
                this.edited_index = this.dados_lista.indexOf(item)
                // atribui os dados que se encontram nessa posição
                this.dados = Object.assign({}, item)
                // retira os dados presentes na posição do vetor
                this.dados_lista.splice(this.edited_index, 1)
                // caso seja a lista de tags
                if(this.lista.planilha && this.planilha_id == '1'){
                    // percorremos as colunas de dados
                    for(let i = 0; i < this.tags_planilha.dados.length; i++){
                        // limpa os dados importados
                        this.tags_planilha.dados[i].splice(this.edited_index, 1)
                    }  
                }
                // ferramenta do vue para atualização imediata
                this.$nextTick(() => {
                    // atribui o array vazio ao vetor com os dados que serão apagados
                    this.dados = Object.assign({}, this.dados_vazio)
                    // volta o marcador de posições para sua posição inicial
                    this.edited_index = -1
                })
            },
            // função para abrir a modal de edição dos dados
            async editarNumero(item){
                // atribui a posição dos dados que serão editados à variável
                this.edited_index = this.dados_lista.indexOf(item)
                // atribui os dados que se encontram nessa posição
                this.dados = Object.assign({}, item)
                // mostra a modal para a edição dos dados
                this.dialog_numero = true
            },
            // função para fechar a modal de cadastro de lista de transmissão
            async closeLista(){
                // fecha a modal
                this.dialog_lista = false
                // limpa os dados locais da lista
                this.lista = {
                    name: '',
                    empresa: '',
                    bot: '',
                    queue: '',
                    planilha: false,
                },
                this.dados_lista = []
            },
            // função para fechar a modal de cadastro/edição de dados 
            async closeNumero(){
                // fecha a modal
                this.dialog_numero = false
                // ferramenta do vue para atualização imediata
                this.$nextTick(() => {
                    // atribui o array vazio ao vetor com os dados já salvos
                    this.dados = Object.assign({}, this.dados_vazio)
                    // volta o marcador de posições para sua posição inicial
                    this.edited_index = -1
                })
            },
            // função para abrir a modal de cadastro de números
            async modalNumbers(){
                // caso ainda não haja valores no vetor
                if(this.dados.numbers == ''){
                    // atribui um vetor vazio
                    this.dados.numbers = []
                }
                // abre a modal para cadastro de números
                this.dialog_adicionar_numeros = true
            },
            // função para adicionar um campo de número na modal de números
            async adicionarContato(){
                this.dados.numbers.push('')
            },
            // função para remover um número da modal de adicionar números
            async removeContato(index){
                if(index > -1){
                    this.dados.numbers.splice(index, 1)
                }
            },
            // função para fechar a modal de cadastro de números em caso de cancelamento
            async closeAdicionarNumeros(){
                // limpa os dados locais
                this.dados.numbers = []
                // fecha a modal
                this.dialog_adicionar_numeros = false
            },
            // função para fechar a modal de vizualização dos estágios
            async closeEstagio(){
                // fecha a modal
                this.dialog_dados_usuario_lista = false
                // limpa os dados locais
                this.dados_usuario.nome = ''
                this.dados_usuario.identificacao = ''
                this.estagios = [{
                    nome: '',
                    dia: '',
                    horario: '',
                }]
            },
            // função para fechar a modal de adicionar números
            async adicionarNumeros(){
                // caso os campos do formulário estejam válidos
                if(this.$refs.form_telefones.validate()){
                    // fecha a modal
                    this.dialog_adicionar_numeros = false
                }
            },
            // função para importar uma planilha padrão
            planilhaPadrao(rows){
                // roda um for para atribuir os dados
                for(let i = 0; i < rows.length; i++){
                    // armazena na constante os valores de cada campo
                    const dados_planilha = rows[i]
                    // caso os campos da planilha estejam corretamente preenchidos
                    if(i > 0 && dados_planilha[0] != '' && dados_planilha[2] != ''){
                        // atribui os dados à variável
                        this.dados = {
                            name: dados_planilha[0],
                            cpf: dados_planilha[1],
                            numbers: dados_planilha[2],
                            message: ''
                        }
                        this.dados.numbers = this.dados.numbers.split(', ')
                        // atribui os dado ao vetor
                        this.dados_lista.push(this.dados)
                    }
                }
            },
            // função para importar uma planilha de tags
            planilhaTags(rows){
                // roda um for para atribuir os dados
                for(let i = 0; i < rows.length; i++){
                    // armazena na constante os valores de cada campo
                    const dados_planilha = rows[i]
                    // verifica as colunas presentes na coluna
                    if(i == 0){
                        // percorre as colunas da planilha
                        for(let j = 1; j < dados_planilha.length; j++){
                            // atribui a tag
                            this.tags_planilha.tags.push(dados_planilha[j])
                            // atribui um array para armazenar os dados
                            this.tags_planilha.dados.push([])
                            // atribui no texto
                            this.texto_tags = this.texto_tags + dados_planilha[j] + ', '
                        }
                    // caso os campos estejam corretamente preenchidos
                    }else if(dados_planilha[0] != '' && dados_planilha[1] != ''){
                        // atribui os dados à variável
                        this.dados = {
                            name: dados_planilha[1],
                            cpf: '',
                            numbers: dados_planilha[0],
                            message: ''
                        }
                        // percorre os dados das tags dinâmicas
                        for(let j = 1; j < dados_planilha.length; j++){
                            // atribui o dado
                            this.tags_planilha.dados[j - 1].push(dados_planilha[j])
                        }
                        this.dados.numbers = this.dados.numbers.split(', ')
                        // atribui os dados ao vetor
                        this.dados_lista.push(this.dados)
                    }
                }
            },
            // função para importar uma planilha com as mensagens personalizadas
            planilhaMensagem(rows){
                // roda um for para atribuir os dados
                for(let i = 0; i < rows.length; i++){
                    // armazena na constante os valores de cada campo
                    const dados_planilha = rows[i]
                    // caso os campos da planilha estejam corretamente preenchidos
                    if(i > 0 && dados_planilha[0] != '' && dados_planilha[1] != ''){
                        // atribui os dados à variável
                        this.dados = {
                            name: '',
                            cpf: '',
                            numbers: dados_planilha[0],
                            message: dados_planilha[1]
                        }
                        this.dados.numbers = this.dados.numbers.split(', ')
                        // atribui os dados ao vetor
                        this.dados_lista.push(this.dados)
                    }
                }
            },
            // função para formatar o texto das tags
            formataTexto(){
                // percorremos todos os dados importados
                for(let i = 0; i < this.tags_planilha.dados[0].length; i++){
                    // atribui a mensagem da transmissão
                    let mensagem = this.mensagem_tags
                    // percorremos todas as tags importadas
                    for(let j = 0; j < this.tags_planilha.tags.length; j++){
                        // atribui a tag atual
                        let tag = this.tags_planilha.tags[j]
                        // atribui o dado atual
                        let dados = this.tags_planilha.dados[j][i]
                        // substituimos a tag pelo dado
                        mensagem = String(mensagem).replace('{' + tag + '}', dados)
                    }
                    // atribuimos a mensagem já formatada
                    this.dados_lista[i].message = mensagem
                }
            },
            // função para formatar um telefone e exibir
			formatarTelefone(value){
				const format = (tel) => {
					let telefone = tel.replace(/\D/g, '')
					if(telefone.length === 13){
						return '+' + telefone.slice(0,2) + ' (' + telefone.slice(2,4) + ') ' + telefone.slice(4,5) + ' ' + telefone.slice(5,9) + '-' + telefone.slice(9,13)
					}else if(telefone.length === 12){
						return '+' + telefone.slice(0,2) + ' (' + telefone.slice(2,4) + ') ' + ' ' + telefone.slice(4,8) + '-' + telefone.slice(8,12)
					}else if(telefone.length === 11){
						return '(' + telefone.slice(0,2) + ') ' + telefone.slice(2,3) + ' ' + telefone.slice(3,7) + '-' + telefone.slice(7,11)
					}else if(telefone.length === 10){
						return '(' + telefone.slice(0,2) + ') ' + telefone.slice(2,6) + '-' + telefone.slice(6,10)
					}else if(telefone.length === 9){
						return telefone.slice(0,1) + ' ' + telefone.slice(1,5) + '-' + telefone.slice(5,9)
					}else if(telefone.length === 8){
						return telefone.slice(0,4) + '-' + telefone.slice(4,8)
					}else{
						return 'Número inválido'
					}
				}
				if(value){
					let fones = value.split(',')
					fones = fones.map(fone => format(fone))
					fones = fones.join(', ')
					return `${fones}`
				}else{
					return ''
				}
			},
        },
        // funções que rodam quando o componente é montado
        mounted(){
            // função de início do componente
            this.init()
        },
    }
</script>
<!--scss do front-->
<style lang="scss">
    #lista{
        display: flex;
        max-height: 100%;
        padding: 24px;
        flex-wrap: wrap;
        .tabela-lista{
            width: 100%;
            background-color: #fff;
            border-radius: 10px;
            .filtros{
                form{
                    margin-left: 5px;
                    display: flex;
                    flex-wrap: nowrap;
                    justify-content: space-between;
                    align-items: center;
                    svg{
                        font-size: 20px;
                        color: var(--secondary-color);
                    }
                    .v-input{
                        display: flex;
                        flex-wrap: nowrap;
                        justify-content: space-between;
                        align-items: center;
                        padding: 0 10px;
                    }
                }
            }
            .nova-lista{
                display: flex;
                justify-content: center;
                padding-bottom: 0;
                button{
                    i, svg{
                        margin-right: 10px;
                    }
                    text-transform: capitalize;
                }
            }
        }
    }
    .importar-planilha{
        i, svg{
            margin-right: 10px;
        }
        text-transform: capitalize;
        display: flex;
    } 
    .menu{
        display: flex;
        flex: 1 1 auto;
        .inputs{
            display: flex;
            flex-wrap: wrap;
            flex: 1 1 auto;
        }
        .remover-menu{
            min-width: inherit;
            margin-top: 20px;
            margin-left: 13px;
        }
    }
</style>